import { showUnexpectedErrorNotification } from './notifications';

import {
  VERIFY_MFA_ACCESS,
  VERIFY_MFA_ACCESS_ERROR,
  VERIFY_MFA_ACCESS_SUCCESS,
} from '../actions';

import {
  showEnterCodeOverlayForSecureSession,
  showOtherOptionsOverlay,
} from '../../../bundles/mfa/state/show-overlay-action-creators';

// eslint-disable-next-line no-unused-vars
export const handleCodeSendError = (dispatch) => (err) => {
  dispatch(showUnexpectedErrorNotification());
};

export const doMfaCodeChallenge =
  ({ openModal }) =>
  (dispatch) => {
    dispatch(
      showOtherOptionsOverlay({
        verificationType: 'secureSession',
        openModal,
        onCodeSendSuccess: () => {
          dispatch(
            showEnterCodeOverlayForSecureSession({
              openModal,
            })
          );
        },
      })
    );
  };

export const verifyMfaAccess =
  () =>
  (dispatch, getState, { apiFetch }) => {
    dispatch({ type: VERIFY_MFA_ACCESS });
    return apiFetch(`/apiproxy/v1/account/verify-mfa-access`, {
      method: 'post',
    })
      .then((data) => {
        dispatch({ type: VERIFY_MFA_ACCESS_SUCCESS, payload: data });
        return data;
      })
      .catch((error) => {
        dispatch({ type: VERIFY_MFA_ACCESS_ERROR, payload: error });
      });
  };

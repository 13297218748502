/* global PasswordCredential */
import { parseResponseBody } from 'shared/app/utils/fetch-utils';

const credentialsSupported = () => {
  return Boolean(
    // Calling credential mgt api methods will cause a DOMException
    // when called in a insecure location (http, and not local).
    // https://www.chromium.org/Home/chromium-security/prefer-secure-origins-for-powerful-new-features
    window.isSecureContext &&
      window.navigator.credentials &&
      // Chrome 60+, Firefox 60+
      window.navigator.credentials.preventSilentAccess
  );
};

const passwordCredentialSupported = () => {
  // Only detect the credential type used, see:
  // https://developers.google.com/web/updates/2018/03/webauthn-credential-management#what_is_webauthn
  return credentialsSupported() && Boolean(window.PasswordCredential);
};

const storeCredential = ({ username, password }) => {
  if (!passwordCredentialSupported()) {
    return Promise.resolve();
  }

  const credential = new PasswordCredential({
    id: username,
    name: username,
    password,
  });

  return navigator.credentials.store(credential);
};

const getCredential = () => {
  return navigator.credentials.get({
    password: true,
    // Request confirmation of credential with user interaction,
    // i.e. Account chooser UI or autoSignin confirmation UI
    mediation: 'optional',
  });
};

const autoSignin = (formBody) => {
  return fetch(`/apiproxy/v1/account/signin`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(formBody),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      // Custom header for newrelic tracking purposes
      'X-AutoSignin': 'true',
    },
  })
    .then(parseResponseBody)
    .then(({ response, body }) => {
      if (response.status === 200) {
        return body;
      }
      const httpError = new Error(response.statusText);
      httpError.status = response.status;
      httpError.data = body;
      httpError.requestBody = formBody;
      throw httpError;
    });
};

const attemptAutoSignin = ({ postData, signinAction }) => {
  if (!passwordCredentialSupported()) {
    return Promise.reject(new Error('Credentials api not supported'));
  }

  return getCredential().then((credentials) => {
    if (credentials && credentials.type === 'password') {
      const userData = Object.assign(
        {
          username: credentials.id,
          password: credentials.password,
          rememberMe: true,
        },
        { transactionSubType: 'autoSignin' },
        postData
      );
      signinAction();
      return autoSignin(userData);
    }
    throw new Error('Missing or wrong type of credentials');
  });
};

const disableAutoSignin = () => {
  return passwordCredentialSupported()
    ? navigator.credentials.preventSilentAccess()
    : Promise.resolve();
};

export { attemptAutoSignin, disableAutoSignin, storeCredential };

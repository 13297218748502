import {
  FETCH_USER_MFA_FACTORS,
  FETCH_USER_MFA_FACTORS_SUCCESS,
  FETCH_USER_MFA_FACTORS_ERROR,
} from '../../actions';
import { API_PROXY_V1 } from 'shared/app/utils/create-gql-fetcher';
import { GET_USER_MFA_FACTORS } from 'shared/universal/gql-operation-ids';

export const fetchUserMfaFactors =
  () =>
  (dispatch, getState, { gqlFetch }) => {
    dispatch({ type: FETCH_USER_MFA_FACTORS });
    return gqlFetch({
      operationId: GET_USER_MFA_FACTORS,
      destinationType: API_PROXY_V1,
    })
      .then((userResult) => {
        dispatch({
          type: FETCH_USER_MFA_FACTORS_SUCCESS,
          payload: userResult.user.mfaFactors,
        });

        return userResult?.user?.mfaFactors;
      })
      .catch((error) =>
        dispatch({
          type: FETCH_USER_MFA_FACTORS_ERROR,
          error,
        })
      );
  };

import { currentCountryCodeSelector } from 'shared/app/state/selectors/locales';
import { guestEmailMarketingOptInSelector } from 'shared/app/state/selectors/ordering';

import {
  GUEST_SIGN_IN,
  GUEST_SIGN_IN_SUCCESS,
  GUEST_SIGN_IN_ERROR,
  CREATE_GUEST_PROFILE,
  CREATE_GUEST_PROFILE_SUCCESS,
  CREATE_GUEST_PROFILE_ERROR,
  DELETE_GUEST_COOKIES,
  DELETE_GUEST_COOKIES_SUCCESS,
  DELETE_GUEST_COOKIES_ERROR,
} from './types';

export const createGuestSession =
  ({ reputation }) =>
  (dispatch, getState, { apiFetch }) => {
    const market = currentCountryCodeSelector(getState());
    dispatch({ type: GUEST_SIGN_IN });
    apiFetch(`/apiproxy/v1/account/signin/guest`, {
      method: 'post',
      body: {
        reputation,
        market,
      },
    })
      .then(() => {
        dispatch({ type: GUEST_SIGN_IN_SUCCESS });
      })
      .catch(() => {
        dispatch({ type: GUEST_SIGN_IN_ERROR });
      });
  };

export const createGuestProfile =
  ({ firstName, email }) =>
  (dispatch, getState, { apiFetch }) => {
    const emailMarketingOptIn = guestEmailMarketingOptInSelector(getState());

    dispatch({ type: CREATE_GUEST_PROFILE });
    return apiFetch(`/apiproxy/v1/account/guest-profile`, {
      method: 'post',
      body: {
        firstName,
        email,
        emailMarketingOptIn,
      },
    })
      .then(() => {
        dispatch({ type: CREATE_GUEST_PROFILE_SUCCESS });
      })
      .catch((err) => {
        const guestProfileError = new Error(
          'Error while creating guest profile'
        );
        guestProfileError.guestSessionExpired = err?.guestSessionExpired;
        guestProfileError.httpStatus = err?.httpStatus;
        dispatch({ type: CREATE_GUEST_PROFILE_ERROR }, guestProfileError);
        throw guestProfileError;
      });
  };

export const deleteGuestCookies =
  () =>
  (dispatch, getState, { apiFetch }) => {
    dispatch({ type: DELETE_GUEST_COOKIES });
    return apiFetch(`/apiproxy/v1/account/guest/signout`, {
      method: 'post',
      body: {},
    })
      .then(() => {
        dispatch({ type: DELETE_GUEST_COOKIES_SUCCESS });
      })
      .catch(() => {
        dispatch({ type: DELETE_GUEST_COOKIES_ERROR });
      });
  };
